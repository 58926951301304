import React, { Component } from 'react';

class TagComponent extends Component {
    handleRemove = () => {
        const { onRemove } = this.props;
        if (onRemove) {
            onRemove();
        }
    };

    render() {
        const { name } = this.props;

        return (
            <div style={styles.tagContainer}>
                <span style={styles.tagName}>{name}</span>
                {(!this.props.hideClose) ? <button onClick={this.handleRemove} style={styles.removeButton}>
                    &times;
                </button> : ""}
            </div>
        );
    }
}

const styles = {
    tagContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        marginRight: '5px',
    },
    tagName: {
        marginRight: '8px',
    },
    removeButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        color: '#999',
    },
};

export default TagComponent;
