import React, { Component } from 'react';



import MainComponent from './comps/MainComponent';
import './App.css';
import { Map, List, Record } from 'immutable';
import { Fragment } from 'react';
import {
  Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button
} from 'reactstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./comps/auth/auth/awsconfig";
const { components, federated, formFields, services } = require('./comps/auth/theme/settings');
Amplify.configure(awsconfig);

const urls = {
  prod: process.env.REACT_APP_RECO_PATH,
  stag: process.env.REACT_APP_RECO_PATH_STAG,
  qa: process.env.REACT_APP_RECO_PATH_QA,
  dev: process.env.REACT_APP_RECO_PATH_DEV,
}
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data:
      {
        stage: "prod"

      }
    }
  }
  setStage = (stage) => {
    this.setState({
      data: {
        stage: stage
      }
    })
  }
  componentDidMount() {
    // fetch('http://jsonplaceholder.typicode.com/users')
    // .then(res => res.json())
    // .then((datav) => {
    //   const {data}= this.state;
    //   this.setState({ data: data.contact.merge(datav) })
    //   console.log(this.state.contacts)
    // })
    // .catch(console.log)

  }

  render() {
    const { data } = this.state;

    return (
      <MainComponent cognito={this.props} stage={data.stage} setStage={this.setStage} urls={urls} ></MainComponent>
    );
  }
}

// export default App;

export default withAuthenticator(App, {
  hideSignUp: true,
  socialProviders: ["google"],
  federated: federated,
  services: services,
  formFields: formFields,
  components: components
});
