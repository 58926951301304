import React, { Component, Fragment } from "react";

import Immutable, { is } from "immutable";

import * as XLSX from 'xlsx';

import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";
import TagComponent from "../element/TagComponent";
import TaskComponent from "../element/TaskComponent";


import '../../css/ImageTable.css';
const { Base64 } = require("js-base64");


class ProductPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_name: "mtest",
			data: [],
			project_list: [],
			selectedProjectName: "",
			isModalOpen: false,
			modalImageSrc: '',
			isRejctModelOpen: false,
			rejectTargetIdx: undefined,
			rejectReason: "",
			currentRejectReason: "",
			search_seq: "",
			modalImageSrc2: '',
			leftCount: -1,
			currentCheckTag: "featured",
			onDownloadAll: false,
			currentDownloadUrl: "",
			possbileTagList: [],
			taskTags: [],
			newTagName: "",
			newTagContent: "",
			currentTagIdx: -1,
			taskList: [],
			currentSelectedTask: undefined,
			statusCounts: {
				fail: 0,
				success: 0,
				accept: 0,
				hold: 0,
				ready: 0
			},
			mixModalOpen: false,
			mixModelSrc: "",
			mixModelSeq: ""

		};
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.modalRef = React.createRef();
		window.that = this;
	}



	handleKeyPress(event) {
		switch (event.key.toLowerCase()) {
			case "a":
				this.updateProductStatus(this.state.currentItem.idx, "accept");
				break;
			case "b":
				this.handleB();
				break;
			case "c":
				this.updateProductStatus(this.state.currentItem.idx, "accept");
				break;
			case "d":
				this.handleD();
				break;
			case "f":
				this.updateProductStatus(this.state.currentItem.idx, "fail");
				break;
			case "h":
				this.updateProductStatus(this.state.currentItem.idx, "hold");
				break;
			case "n":
				this.refreshData();
				break;
			case "s":
				this.updateMainTask(this.state.currentItem.idx, this.state.currentSelectedTask.seq);
				break;
			default:
				console.log("Key not bound to a function.");
		}
	}




	handleN() {
		this.refreshData();
	}


	handleClickOutside = (event) => {
		console.log("click outside")
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};


	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
		window.removeEventListener("keydown", this.handleKeyPress);
	}


	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getProjectList();
		this.getPossbleTagList();
		this.getImageData();
		window.addEventListener("keydown", this.handleKeyPress);

	}
	getPossbleTagList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/tag`,
			params: {

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag_list = JSON.parse(result.data.body).tag_list;
		tag_list.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		this.setState({ possbileTagList: tag_list, currentTagIdx: tag_list[0].idx }, () => {


		});
	}
	getTaskTagList = async (task_idx) => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/history/tag`,
			params: {
				history_idx: task_idx
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag_list = JSON.parse(result.data.body).tag_list;
		console.log(tag_list);
		tag_list.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		this.setState({ taskTags: tag_list }, () => {


		});
	}
	getProductTask = async (product_id, project_name) => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/task`,
			params: {
				product_id: product_id,
				project_name: project_name,
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tasks = JSON.parse(result.data.body).tasks;


		this.setState({ taskList: tasks }, () => {
			if (this.state.currentItem.seq1 == undefined) {
				this.setState({ currentSelectedTask: tasks[0] });
			}
			else {
				//find the task with the same seq1
				let targetTask = tasks.find((task) => task.seq == this.state.currentItem.seq1);
				this.setState({ currentSelectedTask: targetTask });
			}


		});
	}
	getProjectList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/list`,
			params: {


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const projects = JSON.parse(result.data.body).project_list;

		this.setState({ project_list: projects, selectedProjectName: projects[0].project_name }, () => {

			this.getImageData();

		});
	}
	productReRun = async (product_idx, product_id) => {
		try {
			const result = await axios({
				method: "Put",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/rerun`,
				data: {
					product_idx: product_idx + ""

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});

			alert("추가 요청이  처리되었습니다. 잠시 기다려주세요(검수 전 상태로 변경됩니다.)");
			this.getProductTask(product_id, this.state.selectedProjectName);
		} catch (e) {
			alert(e);
		}
		this.updateProductStatus(this.state.currentItem.idx, "ready");
	}
	getImageData = async () => {
		if (this.state.selectedProjectName == "") {
			return;
		}
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product`,
			params: {
				project_name: this.state.selectedProjectName,
				random_one: "true",

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tasks = JSON.parse(result.data.body).tasks;
		console.log(JSON.parse(result.data.body).tasks)
		const leftCount = JSON.parse(result.data.body).left_count;

		//if task item has discard field and if its true, set discarded to true
		tasks.forEach((task) => {
			if (task.discard && task.discard == "true") {
				task.discarded = true;
			}
		});

		const statusCounts = {
			fail: 0,
			success: 0,
			accept: 0,
			hold: 0,
			ready: 0
		};
		tasks.forEach(item => {
			if (statusCounts.hasOwnProperty(item.check_status)) {
				statusCounts[item.check_status]++;
			}
		});


		//set to state
		if (tasks[0] == undefined) {
			this.setState({ statusCounts: statusCounts, data: tasks, leftCount: leftCount, isModalOpen: false });
		}
		else {
			this.setState({ statusCounts: statusCounts, data: tasks, currentItem: tasks[0], leftCount: leftCount, isModalOpen: true, modalImageSrc: this.generateImageUrl(tasks[0]), modalImageSrc2: this.generateResultUrl(tasks[0]) }, () => {
				this.getProductTask(tasks[0].product_id, this.state.selectedProjectName);
			});
		}
	}
	loadMixModel = async (seq) => {

		if (seq == "") {
			alert("모델 시퀀스를 입력해주세요.");
			return;
		}


		this.setState({
			mixModelSrc: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task/image?seq=${seq}&type=model`,
		});

	}
	doMixModel = async (firstSeq, secondSeq) => {

		try {
			const result = await axios({
				method: "Post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task/mix`,
				data: {
					org_seq: firstSeq,
					mix_seq: secondSeq

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});

			alert("합성 요청이 진행중입니다.");

		} catch (e) {
			alert(e);
		}
		this.closeMixModal();
		this.getProductTask(this.state.currentItem?.product_id, this.state.selectedProjectName);

	}
	generateImageUrl(item) {
		if (!item) {
			return "";
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=input&version=${item.version}`
	}

	generateModelUrl(item) {
		if (!item) {
			return "";
		}

		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output&version=${item.version}`
	}

	generateResultUrl(item) {
		if (!item) {
			return "";
		}

		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=output`
	}

	setCurrentSelectedTask = (task) => {
		this.setState({ currentSelectedTask: task });
	}

	// Open modal if Ctrl is pressed when clicking on result image
	handleResultClick = (item) => {

		let url = "";

		this.setState({ currentItem: item, isModalOpen: true, modalImageSrc: this.generateImageUrl(item), modalImageSrc2: this.generateResultUrl(item) }, () => {
			this.getProductTask(item.product_id, this.state.selectedProjectName);
		},);

	};

	// Close the modal
	closeModal = () => {
		console.log("modal closed");
		this.setState({ isModalOpen: false, modalImageSrc: '', isRejctModelOpen: false, rejectTargetIdx: undefined, isShowRejectReasonOpen: false });
	};
	closeMixModal = () => {

		this.setState({ mixModalOpen: false });
	};


	onValueChanged = (name, value) => {

		console.log("name:", name, ",value:", value)

		this.setState({
			[name]: value

		}, () => {
			if (name == "selectedProjectName") {
				this.getImageData();
			}
		});

	};
	toggleExcludeEvaluated = () => {
		// Toggle excludeEvaluated state
		this.setState((prevState) => ({
			excludeEvaluated: !prevState.excludeEvaluated
		}));
	};
	mixModalOpen = () => {
		this.setState({ mixModalOpen: true });
	}
	updateCheckStatus = async (seq, version) => {

		await axios({
			method: "Put",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/eval`,
			data: {
				seq: seq,


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		alert("재평가 요청이 완료되었습니다.");

	};
	updateProductStatus = async (productIdx, status) => {

		try {
			await axios({
				method: "Post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/update`,
				data: {
					product_idx: productIdx + "",
					check_status: status

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
		} catch (e) {
			//alert(e)
			return;
		}

		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === productIdx) {


					return {
						...item,
						check_status: status,
					}
				}
				return item;

			}),
			currentItem: {
				...prevState.currentItem,
				check_status: status
			}
		}), () => {
			this.getImageData();
		});

	};
	refreshData = () => {
		this.getImageData();
	}
	updateMainTask = async (productIdx, seq) => {

		try {
			await axios({
				method: "Post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/update`,
				data: {
					product_idx: productIdx + "",
					seq: seq

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
		} catch (e) {
			//alert(e)
			return;
		}

		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === productIdx) {


					return {
						...item,
						seq1: seq,
					}
				}
				return item;

			}),
			currentItem: {
				...prevState.currentItem,
				seq1: seq
			}
		}));

	};
	downloadAll = async () => {

		if (this.state.onDownloadAll) {
			window.open(this.state.currentDownloadUrl, '_blank')
			return;
		}

		this.setState({ onDownloadAll: true })
		try {
			const downloadResult = await axios({
				method: 'GET',
				url: `https://dd3lvcyucrnxwxlhlvawluag6m0vffjh.lambda-url.ap-northeast-2.on.aws/`,
				params: {
					project_name: this.state.selectedProjectName,
					check_status: "accept"

				}

			});
			const url = JSON.parse(downloadResult.data.body).downloadUrl;
			console.log(url);
			//window.open(url, '_blank')
			this.setState({ currentDownloadUrl: url })
			//this.setState({ onDownloadAll: false })
		}
		catch (e) {
			alert("파일 키가 없거나 올바른 요청이 아닙니다.")
			console.log(e)
		}

	};
	exportToExcel = () => {

		const filteredData = this.state.data.filter(item => {
			return item.check_status == "accept";
		});
		const rawdata = filteredData.map(item => {
			const { idx, seq, discard, category, product_id, ...rest } = item;  // Remove the discarded property
			return {

				id: product_id,
				check_status: "accept",
				category_2: "-",
				org_file_name: item.org_file_name,
				reject_reason: "-",
				project_name: item.project_name,
				status: "succeed",
				category: item.category,
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public?seq=${item.seq1}` // Custom column based on seq
			};
		});

		// Convert updated data to sheet
		const ws = XLSX.utils.json_to_sheet(rawdata);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Data");

		// Export as Excel file
		XLSX.writeFile(wb, `fssnap_${this.state.selectedProjectName}_${moment().format("YYYY_MM_DD_HH_mm")}.xlsx`);
	};
	getCurrentTile = (status) => {
		if (status == "accept") {
			return (<h2 style={{ fontWeight: "bold", color: "green" }}>{`현재상태 : 확정 (${this.state.leftCount} 남음)`} </h2>)
		}
		else if (status == "hold") {
			return (<h2 style={{ fontWeight: "bold", color: "orange" }}>{`현재상태 : 보류 (${this.state.leftCount} 남음)`}</h2>)
		}
		else if (status == "fail") {
			return (<h2 style={{ fontWeight: "bold", color: "red" }}>{`현재상태 : 실패 (${this.state.leftCount} 남음)`}</h2>)
		}
		else {
			return (<h2 style={{ fontWeight: "bold", color: "purple" }}>{`현재상태 : 검수 전 (${this.state.leftCount} 남음)`}</h2>)
		}
	}
	render() {
		let filteredData = this.state.data;
		if (this.state.excludeEvaluated) {
			filteredData = this.state.data.filter(item => item.check_status == 'ready');
		}

		return (
			<Fragment>
				{this.state.isModalOpen && (
					<div className="modals"
						zIndex={1500}
						style={{

						}} >
						<div className="modal-contents" ref={this.modalRef} style={{ width: "100%", height: "95%" }}>
							<Row>
								<Col md={2}>
									<h2>{`${this.state.currentItem.project_name} : ${this.state.currentItem.product_id}`}</h2>
								</Col>
								<Col md={4}>
									{this.getCurrentTile(this.state.currentItem.check_status)}
								</Col>
								<Col md={6} style={{ alignContent: "end", textAlign: "end" }}>
									<button onClick={this.closeModal} style={{
										background: 'none',
										border: 'none',
										cursor: 'pointer',
										fontSize: '30px',
										color: '#999',
									}}>
										&times;
									</button>
								</Col>
							</Row>
							<Row>
								<Col
									style={{}}
									md={3}>
									<img onClick={() => { this.closeModal() }} style={{

										display: "block",
										"margin-left": "auto",
										"margin-right": "auto",
										"object-fit": "contain",
										"max-height": 800,
										"max-width": 500
									}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
								</Col>
								<Col
									style={{ padding: "5px" }}
									md={5}>
									<img onClick={() => { this.closeModal() }} style={{
										display: "block",
										border: "1px solid black",
										"margin-left": "auto",
										"margin-right": "auto",
										"object-fit": "contain",
										"max-height": 800
									}} src={this.generateModelUrl(this.state.currentSelectedTask)} alt="Large version" className="modal-image" />


								</Col>
								<Col
									style={{
										display: "flex", // Enable Flexbox
										flexWrap: "wrap", // Allow wrapping to the next row
										justifyContent: "left", // Align items horizontally
										"align-content": "flex-start",
										"column-gap": "0px",
										"row-gap": "1px",

									}}
									md={2}>

									{
										this.state.taskList.map((item, index) => (

											<TaskComponent item={item} onClick={this.setCurrentSelectedTask}
												isSelected={this.state.currentSelectedTask?.seq == item.seq}
												isCurrent={this.state.currentItem?.seq1 == item.seq}
											></TaskComponent>
										))
									}

								</Col>
								<Col

									style={{}}
									md={2}>
									<Row>
										<table style={{ width: "100%", border: "1px solid black", "border-collapse": "collapse" }}>
											<thead>
												<tr>
													<th style={{ width: "30%" }}>Prop</th>
													<th style={{ width: "50%" }}>Value</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="tb_td">seq</td>
													<td className="tb_td">{this.state.currentSelectedTask?.seq}</td>
												</tr>
												<tr>
													<td className="tb_td">카테고리</td>
													<td className="tb_td">{this.state.currentSelectedTask?.category_1}</td>
												</tr>
												<tr>
													<td className="tb_td">Total</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_total}</td>
												</tr>
												<tr>
													<td className="tb_td">Hip</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_hip}</td>
												</tr>

												<tr>
													<td className="tb_td">Waist</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_waist}</td>
												</tr>
												<tr>
													<td className="tb_td">Breast</td>
													<td>{this.state.currentSelectedTask?.size_breast}</td>
												</tr>
												<tr>
													<td className="tb_td">Sleeve</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_sleeve}</td>
												</tr>
												<tr>
													<td className="tb_td">Shoulder</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_shoulder}</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)로고 및 텍스트 평가</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_logo_letter) >= 9.0) ? "green" : "red"
														}}
													>{this.state.currentSelectedTask?.ai_eval_logo_letter}
													</td>
												</tr>
												<tr>
													<td className="tb_td"
													>(AI)손 평가</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_hands) >= 9.0) ? "green" : "red"
														}}>{this.state.currentSelectedTask?.ai_eval_hands}
													</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)포즈</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_representation) >= 9.0) ? "green" : "red"
														}}>{this.state.currentSelectedTask?.ai_eval_representation}
													</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)General</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_resemblance) >= 9.0) ? "green" : "red"
														}}>{this.state.currentSelectedTask?.ai_eval_resemblance}
													</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)전체 평균</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (((parseFloat(this.state.currentSelectedTask?.ai_eval_resemblance) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_representation) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_hands) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_logo_letter)) / 4) >= 9.0) ? "green" : "red"
														}}>{
															((parseFloat(this.state.currentSelectedTask?.ai_eval_resemblance) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_representation) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_hands) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_logo_letter)) / 4).toFixed(2)
														}
													</td>
												</tr>
												{/* <tr>
												<td className="tb_td">사람 평가</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentSelectedTask?.check_status == "accept") ? "green" : "red"
													}}

												>{this.state.currentSelectedTask?.check_status}</td>
											</tr> */}
											</tbody>
										</table>
										<div>
											{
												this.state.taskTags.map((item, index) => (
													<TagComponent name={item.name} onRemove={() => {
														this.removeTag(item.idx, this.state.rejectTargetIdx);
													}}></TagComponent>
												))
											}
										</div>
										<Col md={12} style={{ textAlign: "right" }}>


											<Row>
												<Col md={12}>
													<Button
														color="success"
														onClick={() => {
															this.updateMainTask(this.state.currentItem.idx, this.state.currentSelectedTask.seq);
														}}
													>
														이미지고정(S)
													</Button>

													<Button
														color="primary"
														onClick={() => {
															this.updateProductStatus(this.state.currentItem.idx, "accept");
														}}
													>
														확정(A)
													</Button>

													<Button
														color="warning"
														onClick={() => {
															this.updateProductStatus(this.state.currentItem.idx, "hold");
														}}
													>
														보류(H)
													</Button>


												</Col>

											</Row>
											<Row style={{ marginTop: 20 }}>

												<Col md={12}>
													<Button
														color="danger"
														onClick={() => {
															this.updateProductStatus(this.state.currentItem.idx, "fail");
														}}
													>
														실패(F)
													</Button>
													<Button
														color="info"
														onClick={() => {
															this.productReRun(this.state.currentItem.idx, this.state.currentItem.product_id);
														}}
													>
														후보추가
													</Button>
													{/* 
													<Button
														color="success"
														onClick={() => {
															this.getProductTask(this.state.currentItem.product_id, this.state.selectedProjectName);
														}}
													>
														이미지 새로고침
													</Button> */}
													<Button
														color="info"
														onClick={() => {
															this.mixModalOpen();
														}}
													>
														Mix
													</Button>
												</Col >

											</Row>
											<Row style={{ marginTop: 20 }}>
												<Col md={12}>


													<Button
														color="primary"
														onClick={() => {
															this.refreshData();
														}}
													>
														다음(N)
													</Button>
												</Col >

											</Row>
										</Col>

									</Row>
									<Row>

									</Row>
								</Col>


							</Row>
						</div>

					</div>)
				}
				{
					this.state.mixModalOpen && (
						<div className="modals" zIndex={1600}>

							<div className="modal-contents" ref={this.modalRef} style={{ width: "90%", height: "85%" }}>
								<Row>
									<Col md={2}>
										<h2>{`모델 합성하기`}</h2>
									</Col>
									<Col md={4}>
									</Col>
									<Col md={3}>


									</Col>
									<Col md={2} style={{}}>
										<Row>
											<Col md={6}>
												<Input style={{ width: "100%" }} type="text" value={this.state.mixModelSeq} onChange={(e) => this.onValueChanged("mixModelSeq", e.target.value)} />
											</Col>
											<Col md={6}>
												<button
													className="btn btn-primary"
													onClick={() => {
														this.loadMixModel(this.state.mixModelSeq);
													}}
													style={{ marginLeft: "auto" }}
												>
													모델 불러오기
												</button>

												<button
													className="btn btn-success"
													onClick={() => {
														this.doMixModel(this.state.currentItem.seq1, this.state.mixModelSeq);
													}}
													style={{ marginLeft: "auto" }}
												>
													합성하기
												</button>
											</Col>
										</Row>
									</Col>

									<Col md={1} style={{ alignContent: "end", textAlign: "end" }}>
										<button onClick={this.closeMixModal} style={{
											background: 'none',
											border: 'none',
											cursor: 'pointer',
											fontSize: '30px',
											color: '#999',
										}}>
											&times;
										</button>
									</Col>
								</Row>
								<Row>
									<Col
										style={{}}
										md={4}>
										<img onClick={() => { this.closeModal() }} style={{

											display: "block",
											"margin-left": "auto",
											"margin-right": "auto",
											"object-fit": "contain",
											"max-height": 500,
											"max-width": 500
										}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
									</Col>
									<Col
										style={{ padding: "5px" }}
										md={4}>
										<img onClick={() => { this.closeModal() }} style={{
											display: "block",
											border: "1px solid black",
											"margin-left": "auto",
											"margin-right": "auto",
											"object-fit": "contain",
											"max-height": 800
										}} src={this.state.mixModelSrc} alt="Large version" className="modal-image" />


									</Col>
								</Row>

							</div>
						</div>)
				}
				{
					this.state.isRejctModelOpen && (
						<div className="modals" zIndex={1600}>

							<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
								<Row>
									<Col md={12}>


										{
											this.state.taskTags.map((item, index) => (
												<TagComponent name={item.name} onRemove={() => {
													this.removeTag(item.idx, this.state.rejectTargetIdx);
												}}></TagComponent>
											))
										}

									</Col>
									<Col md={9}>
										<Label
											for="exampleFile"
											sm={2}
										>
											제외 사유
										</Label>
										<textarea
											style={{ height: "250px" }}
											type="text"
											className="form-control"
											placeholder="제외 사유를 입력해주세요"
											value={this.state.rejectReason}
											onChange={(val) => {
												this.onValueChanged("rejectReason", val.target.value);
											}}
										/>
									</Col>
									<Col md={3}>


										<Row>
											검수 태그
											<Input value={this.state.currentTagIdx} onChange={(e) => this.onValueChanged("currentTagIdx", e.target.value)} id="testset" name="testset" type="select">

												{this.state.possbileTagList.map((item, index) => (
													<option value={item.idx}>{item.name}</option>
												))}
											</Input>
											<Col md={12} style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
												<button
													className="btn btn-primary"
													onClick={() => {
														this.registerTag(this.state.currentTagIdx, this.state.rejectTargetIdx);
													}}
													style={{ marginLeft: "auto" }}
												>
													등록
												</button>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												태그
												<input style={{ width: "100%" }} type="text" value={this.state.newTagName} onChange={(e) => this.onValueChanged("newTagName", e.target.value)} />
											</Col>
											<Col md={12}>
												태그 내용
												<textarea style={{ width: "100%" }} type="text" value={this.state.newTagContent} onChange={(e) => this.onValueChanged("newTagContent", e.target.value)} />
											</Col>
											<Col md={12} style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
												<button
													className="btn btn-primary"
													onClick={() => {
														this.registerNewTag();
													}}
													style={{ marginLeft: "auto" }}
												>
													신규 태그 생성
												</button>
											</Col>


										</Row>
									</Col>
								</Row>
								<Row>

									<Button
										color="danger"

										onClick={() => {
											this.updateRejectStatus(this.state.rejectTargetIdx, "reject");
										}}
									>
										재평가
									</Button>
								</Row>
							</div>
						</div>)
				}
				{
					this.state.isShowRejectReasonOpen && (
						<div className="modals">

							<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
								<Row>
									<Label
										for="exampleFile"
										sm={2}
									>
										제외 사유
									</Label>
									<textarea
										style={{ height: "250px" }}
										type="text"
										disabled={true}
										className="form-control"
										placeholder="제외 사유"
										value={this.state.currentRejectReason}

									/>
								</Row>

							</div>
						</div>)
				}
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>

									<Col md={2}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.getImageData();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>
									</Col>

									{/* <Col md={3}>
										<Input

											type="text"
											className="form-control"
											placeholder="search_seq"
											value={this.state.search_seq}
											onChange={(val) => {
												this.onValueChanged("search_seq", val.target.value);
											}}
										/>

									</Col> */}
								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{`검수 대기: ${this.state.leftCount}`}
								</Col>
								<Col md={4}>

									<Col md={3}>
										<ButtonGroup>
											<Button
												color="primary"
												onClick={() => {
													this.exportToExcel();
												}}
											>
												Export(Excel)
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={3}>
										<ButtonGroup>
											<Button
												color={(this.state.onDownloadAll != true) ? "primary" : (this.state.currentDownloadUrl) ? "primary" : "secondary"}
												disabled={(this.state.onDownloadAll != true) ? false : (this.state.currentDownloadUrl) ? false : true}
												onClick={() => {
													this.downloadAll();
												}}
											>
												{(this.state.onDownloadAll != true) ? "전체 다운로드" : (this.state.currentDownloadUrl) ? "다운로드 하려면 눌러주세요" : "다운로드 준비 중"}
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={2}>
										프로젝트 선택:
									</Col>
									<Col md={4}>
										<Input value={this.state.selectedProjectName} onChange={(e) => this.onValueChanged("selectedProjectName", e.target.value)} id="selectedProjectName" name="selectedProjectName" type="select">

											{this.state.project_list.map((item) => (
												<option value={item.project_name}>{item.project_name}</option>
											)
											)}


										</Input>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<div className="table-container">
									<table>
										<thead>
											<tr>
												<th>Idx</th>
												<th>product_id</th>

												<th>Original Image</th>

												<th>현재 후보</th>
												<th>검수상태</th>

											</tr>
										</thead>
										<tbody>
											{filteredData.map((item) => (
												<tr
													key={item.idx}


												>
													<td className="table-cell">{item.idx}</td>
													<td className="table-cell">{item.product_id}</td>


													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateImageUrl(item)}
															alt={item.filename}

															onClick={(e) => this.handleResultClick(item, "input")}
														/>
													</td>


													<td className="table-cell">
														<img
															src={this.generateResultUrl(item)}
															alt={`result-${item.filename}`}

															onClick={(e) => this.handleResultClick(item, "result")}  // Handle Ctrl + Click
														/>
													</td>
													<td className="discard-status" >{
														(item.check_status == "accept") ? <span style={{ color: "green", fontWeight: "bold" }}>{"확정"}</span> : (item.check_status == "ready") ? <span style={{ color: "red", fontWeight: "bold" }}>{"검수 전"}</span> : <span style={{ color: "orange", fontWeight: "bold" }}>{"보류"}</span>


													}

													</td>

												</tr>
											))}
										</tbody>
									</table>


								</div>
							</div>
						</Row>
					</Container>
				</div >
			</Fragment >
		);
	}
}

export default ProductPage;
