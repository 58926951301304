import React, { Component, Fragment } from "react";

import Immutable, { is } from "immutable";

import * as XLSX from 'xlsx';

import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";
import TagComponent from "../element/TagComponent";



import '../../css/ImageTable.css';
const { Base64 } = require("js-base64");


class CheckPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_name: "mtest",
			data: [],
			project_list: [],
			selectedProjectName: "",
			isModalOpen: false,
			modalImageSrc: '',
			currentItem: {},
			isRejctModelOpen: false,
			rejectTargetIdx: undefined,
			rejectReason: "",
			currentRejectReason: "",
			excludeEvaluated: true,
			modalImageSrc2: '',
			leftCount: -1,
			currentCheckTag: "featured",
			possbileTagList: [],
			taskTags: [],
			newTagName: "",
			newTagContent: "",
			currentTagIdx: -1,

		};

		this.modalRef = React.createRef();
		window.that = this;
	}







	handleClickOutside = (event) => {
		console.log("click outside")
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};


	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}


	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getProjectList();
		this.getPossbleTagList();
		this.getImageData();

	}
	getPossbleTagList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/tag`,
			params: {

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag_list = JSON.parse(result.data.body).tag_list;
		tag_list.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		this.setState({ possbileTagList: tag_list, currentTagIdx: tag_list[0].idx }, () => {


		});
	}
	getTaskTagList = async (task_idx) => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/task/tag`,
			params: {
				task_idx: task_idx
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag_list = JSON.parse(result.data.body).tag_list;
		console.log(tag_list);
		tag_list.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		this.setState({ taskTags: tag_list }, () => {


		});
	}

	getProjectList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/list`,
			params: {


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const projects = JSON.parse(result.data.body).project_list;

		this.setState({ project_list: projects, selectedProjectName: projects[0].project_name }, () => {

			this.getImageData();

		});
	}
	getImageData = async () => {
		if (this.state.selectedProjectName == "") {
			return;
		}
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/task`,
			params: {
				project_name: this.state.selectedProjectName,
				random_one: "true",

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tasks = JSON.parse(result.data.body).tasks;
		console.log(JSON.parse(result.data.body).tasks)
		const leftCount = JSON.parse(result.data.body).left_count;

		//if task item has discard field and if its true, set discarded to true
		tasks.forEach((task) => {
			if (task.discard && task.discard == "true") {
				task.discarded = true;
			}
		});



		//set to state
		if (tasks[0] == undefined) {
			this.setState({ data: tasks, leftCount: leftCount, isModalOpen: false });
		}
		else {
			this.setState({ data: tasks, currentItem: tasks[0], leftCount: leftCount, isModalOpen: true, modalImageSrc: this.generateImageUrl(tasks[0]), modalImageSrc2: this.generateResultUrl(tasks[0]) });
		}
	}
	generateImageUrl(item) {
		if (!item) {
			return "";
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=input`
	}

	generateModelUrl(item) {
		if (!item) {
			return "";
		}
		if (item.status != "succeed") {
			return "-";
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=model`
	}

	generateResultUrl(item) {
		if (!item) {
			return "";
		}
		if (item.status != "succeed") {
			return "-";
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output`
	}
	updateCheckStatus = async (idx, status) => {
		if (status == "reject") {
			this.setState({ isRejctModelOpen: true, rejectTargetIdx: idx }, () => {
				this.getTaskTagList(idx);
			});
			return;
		}
		let targetSeq = undefined
		let discardStatus = undefined
		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === idx) {
					targetSeq = item.seq

					return {
						...item,
						check_status: status,
					}
				}
				return item;

			}
			)
		}), async () => {

			console.log(targetSeq, discardStatus)
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task`,
				data: {
					seq: targetSeq,
					check_status: status
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			this.closeModal();
			this.getImageData();

		});
	};
	updateRejectStatus = async (idx, status) => {

		let targetSeq = undefined
		let discardStatus = undefined
		let rejectReason = this.state.rejectReason
		// if (rejectReason == "") {
		// 	alert("제외 사유를 입력해주세요")
		// 	return;
		// }
		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === idx) {
					targetSeq = item.seq

					return {
						...item,
						check_status: status,
						reject_reason: rejectReason,
					}
				}
				return item;

			},


			),
			isRejctModelOpen: false,
			rejectReason: ""
		}), async () => {

			console.log(targetSeq, discardStatus)
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task`,
				data: {
					seq: targetSeq,
					check_status: status,
					reject_reason: rejectReason,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			this.getImageData();

		});
	};
	// Open modal if Ctrl is pressed when clicking on result image
	handleResultClick = (item) => {

		let url = "";

		this.setState({ currentItem: item, isModalOpen: true, modalImageSrc: this.generateImageUrl(item), modalImageSrc2: this.generateResultUrl(item) });

	};

	// Close the modal
	closeModal = () => {
		console.log("modal closed");
		this.setState({ isModalOpen: false, modalImageSrc: '', isRejctModelOpen: false, rejectTargetIdx: undefined, isShowRejectReasonOpen: false });
	};
	downloadAll = async () => {
		try {
			const s3SignedUrlData = await axios({
				method: 'GET',
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/download`,
				params: {
					project_name: this.state.selectedProjectName,

				}

			});
			const url = JSON.parse(s3SignedUrlData.data.body).downloadUrl;
			console.log(url);
			window.open(url, '_blank')
		}
		catch (e) {
			alert("파일 키가 없거나 올바른 요청이 아닙니다.")
			console.log(e)
		}
	};
	exportToExcel = () => {

		const filteredData = this.state.data.filter(item => {
			return item.check_status == "accept";
		});

		// Add new column based on 'seq' for non-discarded rows
		const updatedData = filteredData.map(item => {
			const { idx, seq, discard, category_1, product_id, ...rest } = item;  // Remove the discarded property
			return {

				id: product_id,
				...rest,

				category: category_1,
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public?seq=${item.seq}` // Custom column based on seq
			};
		});




		// Convert updated data to sheet
		const ws = XLSX.utils.json_to_sheet(updatedData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Data");

		// Export as Excel file
		XLSX.writeFile(wb, `fssnap_${this.state.selectedProjectName}_${moment().format("YYYY_MM_DD_HH_mm")}.xlsx`);
	};
	handleReRun = async (item) => {

		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task/rerun`,
			params: {
				seq: item.seq

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		//clear data that has same seq
		this.setState((prevState) => ({
			data: prevState.data.map((_item) => {


				if (item.seq === _item.seq) {
					let nitem = _item
					nitem.status = "created";
					return {
						...nitem,
						check_status: undefined,
					}
				}
				return _item;

			}
			)
		}));
	};
	onValueChanged = (name, value) => {

		console.log("name:", name, ",value:", value)

		this.setState({
			[name]: value

		}, () => {
			if (name == "selectedProjectName") {
				this.getImageData();
			}
		});

	};
	toggleExcludeEvaluated = () => {
		// Toggle excludeEvaluated state
		this.setState((prevState) => ({
			excludeEvaluated: !prevState.excludeEvaluated
		}));
	};
	removeTag = async (tag_idx, task_idx) => {
		const result = await axios({
			method: "put",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/task/tag`,
			data: {
				tag_idx: tag_idx,
				task_idx: task_idx,
				remove: true,
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag = JSON.parse(result.data.body).tag;

		this.setState((prevState) => ({
			taskTags: prevState.taskTags.filter((item, index) => {
				return item.idx != tag_idx;
			})

		}))
	}
	registerTag = async (tag_idx, task_idx) => {

		//if tag name exist deny
		this.state.taskTags.forEach((item) => {
			if (item.idx == tag_idx) {
				alert("이미 태그가 붙어있습니다.")
				return;
			}
		})

		const result = await axios({
			method: "put",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/task/tag`,
			data: {
				tag_idx: tag_idx,
				task_idx: task_idx
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag = JSON.parse(result.data.body).tag;
		this.setState((prevState) => ({
			taskTags: [...prevState.taskTags, { idx: tag.tag_idx, name: tag.name, contents: tag.contents }]
		}))
	}
	registerNewTag = async () => {

		//if tag name exist deny
		this.state.possbileTagList.forEach((item) => {
			if (item.name == this.state.newTagName) {
				alert("이미 존재하는 태그명입니다.")
				return;
			}
		})

		const result = await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/tag`,
			data: {
				name: this.state.newTagName,
				contents: this.state.newTagContent
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag = JSON.parse(result.data.body).tag;
		this.setState((prevState) => ({
			//taskTags: [...prevState.taskTags, { idx: tag.idx, name: tag.name, contents: tag.contents }],
			possbileTagList: [...prevState.possbileTagList, { idx: tag.idx, name: tag.name, contents: tag.contents }],
			newTagContent: "",
			newTagName: ""
		}))
		alert("신규 태그가 등록되었습니다.")
	}
	render() {
		let filteredData = this.state.data;
		if (this.state.excludeEvaluated) {
			filteredData = this.state.data.filter(item => item.check_status = 'undefined');
		}

		return (
			<Fragment>
				{this.state.isModalOpen && (
					<div className="modals"
						zIndex={1500}
						style={{

						}} >
						<div className="modal-contents" ref={this.modalRef} style={{ width: "95%", }}>
							<div style={{ width: "100%", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center", textAlign: "center", marginBottom: "5px" }}>{`남은 숫자:${this.state.leftCount}`}</div>

							<div style={{
								width: "100%", display: "flex",

								"justify-content": "space-between"
							}}>
								<img onClick={() => { this.closeModal() }} style={{
									display: "block",
									"margin-left": "auto",
									"margin-right": "auto",
									"object-fit": "contain",
									"max-height": 800,
									"max-width": 500
								}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
								<img onClick={() => { this.closeModal() }} style={{
									display: "block",
									"margin-left": "auto",
									"margin-right": "auto",
									"object-fit": "contain",
									"max-height": 800
								}} src={this.state.modalImageSrc2} alt="Large version" className="modal-image" />
								<div>
									<table style={{ width: "100%", border: "1px solid black", "border-collapse": "collapse" }}>
										<thead>
											<tr>
												<th style={{ width: "70%" }}>Prop</th>
												<th style={{ width: "50%" }}>Value</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="tb_td">seq</td>
												<td className="tb_td">{this.state.data[0].seq}</td>
											</tr>
											<tr>
												<td className="tb_td">카테고리</td>
												<td className="tb_td">{this.state.data[0].category_1}</td>
											</tr>
											<tr>
												<td className="tb_td">Total</td>
												<td className="tb_td">{this.state.data[0].size_total}</td>
											</tr>
											<tr>
												<td className="tb_td">Hip</td>
												<td className="tb_td">{this.state.data[0].size_hip}</td>
											</tr>

											<tr>
												<td className="tb_td">Waist</td>
												<td className="tb_td">{this.state.data[0].size_waist}</td>
											</tr>
											<tr>
												<td className="tb_td">Breast</td>
												<td>{this.state.data[0].size_breast}</td>
											</tr>
											<tr>
												<td className="tb_td">Sleeve</td>
												<td className="tb_td">{this.state.data[0].size_sleeve}</td>
											</tr>
											<tr>
												<td className="tb_td">Shoulder</td>
												<td className="tb_td">{this.state.data[0].size_shoulder}</td>
											</tr>
											<tr>
												<td className="tb_td">(AI)로고 및 텍스트 평가</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentItem.ai_eval_logo_letter == "pass" || this.state.currentItem.ai_eval_logo_letter == "skip") ? "green" : "red"
													}}
												>{this.state.currentItem.ai_eval_logo_letter}<br></br>
													{this.state.currentItem.ai_eval_logo_letter_exp}</td>
											</tr>
											<tr>
												<td className="tb_td"
												>(AI)모델 포즈 및 자세</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentItem.ai_eval_appearance == "pass") ? "green" : "red"
													}}>{this.state.currentItem.ai_eval_appearance}<br></br>
													{this.state.currentItem.ai_eval_appearance_exp}</td>
											</tr>
											<tr>
												<td className="tb_td">(AI)텍스처/핏/모양</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentItem.ai_eval_representation == "pass") ? "green" : "red"
													}}>{this.state.currentItem.ai_eval_representation}<br></br>
													{this.state.currentItem.ai_eval_representation_exp}
												</td>
											</tr>
											<tr>
												<td className="tb_td">(AI)옷 재현도</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentItem.ai_eval_resemblance == "pass") ? "green" : "red"
													}}>{this.state.currentItem.ai_eval_resemblance}<br></br>
													{this.state.currentItem.ai_eval_resemblance_exp}</td>
											</tr>
											<tr>
												<td className="tb_td">(AI)전체 평가</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentItem.ai_eval_overall == "pass") ? "green" : "red"
													}}>{this.state.currentItem.ai_eval_overall}</td>
											</tr>
											<tr>
												<td className="tb_td">사람 평가</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentItem.check_status == "accept") ? "green" : "red"
													}}

												>{this.state.currentItem.check_status}</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div>
							<div style={{ float: "right" }}>
								<Button
									color="info"
									onClick={() => {
										this.updateCheckStatus(this.state.data[0].idx, "accept");
									}}
								>
									수락
								</Button>
								<Button
									color="danger"
									onClick={() => {
										this.updateCheckStatus(this.state.data[0].idx, "reject");
									}}
								>
									제외
								</Button>
							</div>
						</div>

					</div>)}

				{this.state.isRejctModelOpen && (
					<div className="modals" zIndex={1600}>

						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<Row>
								<Col md={12}>


									{
										this.state.taskTags.map((item, index) => (
											<TagComponent name={item.name} onRemove={() => {
												this.removeTag(item.idx, this.state.rejectTargetIdx);
											}}></TagComponent>
										))
									}

								</Col>
								<Col md={9}>
									<Label
										for="exampleFile"
										sm={2}
									>
										제외 사유
									</Label>
									<textarea
										style={{ height: "250px" }}
										type="text"
										className="form-control"
										placeholder="제외 사유를 입력해주세요"
										value={this.state.rejectReason}
										onChange={(val) => {
											this.onValueChanged("rejectReason", val.target.value);
										}}
									/>
								</Col>
								<Col md={3}>


									<Row>
										검수 태그
										<Input value={this.state.currentTagIdx} onChange={(e) => this.onValueChanged("currentTagIdx", e.target.value)} id="testset" name="testset" type="select">

											{this.state.possbileTagList.map((item, index) => (
												<option value={item.idx}>{item.name}</option>
											))}
										</Input>
										<Col md={12} style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
											<button
												className="btn btn-primary"
												onClick={() => {
													this.registerTag(this.state.currentTagIdx, this.state.rejectTargetIdx);
												}}
												style={{ marginLeft: "auto" }}
											>
												등록
											</button>
										</Col>
									</Row>
									<Row>
										<Col md={12}>
											태그
											<input style={{ width: "100%" }} type="text" value={this.state.newTagName} onChange={(e) => this.onValueChanged("newTagName", e.target.value)} />
										</Col>
										<Col md={12}>
											태그 내용
											<textarea style={{ width: "100%" }} type="text" value={this.state.newTagContent} onChange={(e) => this.onValueChanged("newTagContent", e.target.value)} />
										</Col>
										<Col md={12} style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
											<button
												className="btn btn-primary"
												onClick={() => {
													this.registerNewTag();
												}}
												style={{ marginLeft: "auto" }}
											>
												신규 태그 생성
											</button>
										</Col>


									</Row>
								</Col>
							</Row>
							<Row>

								<Button
									color="danger"

									onClick={() => {
										this.updateRejectStatus(this.state.rejectTargetIdx, "reject");
									}}
								>
									제외
								</Button>
							</Row>
						</div>
					</div>)}
				{this.state.isShowRejectReasonOpen && (
					<div className="modals">

						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<Row>
								<Label
									for="exampleFile"
									sm={2}
								>
									제외 사유
								</Label>
								<textarea
									style={{ height: "250px" }}
									type="text"
									disabled={true}
									className="form-control"
									placeholder="제외 사유"
									value={this.state.currentRejectReason}

								/>
							</Row>

						</div>
					</div>)}
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>

									<Col md={2}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.getImageData();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>
									</Col>


								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{`검수(남은 갯수: ${this.state.leftCount})`}
								</Col>
								<Col md={4}>

									<Col md={3}>
										<ButtonGroup>
											<Button
												color="primary"
												onClick={() => {
													this.exportToExcel();
												}}
											>
												Export(Excel)
											</Button>
										</ButtonGroup>
									</Col>

									<Col md={2}>
										프로젝트 선택:
									</Col>
									<Col md={4}>
										<Input value={this.state.selectedProjectName} onChange={(e) => this.onValueChanged("selectedProjectName", e.target.value)} id="selectedProjectName" name="selectedProjectName" type="select">

											{this.state.project_list.map((item) => (
												<option value={item.project_name}>{item.project_name}</option>
											)
											)}


										</Input>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<div className="table-container">
									<table>
										<thead>
											<tr>
												<th>Idx</th>
												<th>Product_id</th>
												<th >Filename</th>
												<th>Status</th>
												<th>Original Image</th>
												<th>Model</th>
												<th>Result</th>
												<th>검수상태</th>
												<th>Control</th>
											</tr>
										</thead>
										<tbody>
											{filteredData.map((item) => (
												<tr
													key={item.idx}

													className={item.check_status == "reject" ? 'discarded-row' : ''}
												>
													<td className="table-cell">{item.idx}</td>
													<td className="table-cell">{item.product_id}</td>
													<td className="table-cell" >{item.org_file_name}</td>
													<td className="table-cell">
														{
															item.status == "succeed" ? <span style={{ color: "green", fontWeight: "bold" }}>{"생성 완료"}</span> :
																(item.status == "failed") ? <span style={{ color: "red" }}>{"생성 실패"}</span> : <span style={{ color: "red" }}>{"생성 중"}</span>
														}
													</td>
													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateImageUrl(item)}
															alt={item.filename}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(item, "input")}
														/>
													</td>
													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateModelUrl(item)}
															alt={`model-${item.filename}`}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(item, "model")}
														/>
													</td>

													<td className="table-cell">
														<img
															src={this.generateResultUrl(item)}
															alt={`result-${item.filename}`}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(item, "result")}  // Handle Ctrl + Click
														/>
													</td>
													<td className="discard-status" >{
														(item.check_status == "accept") ? <span style={{ color: "green", fontWeight: "bold" }}>{"수락"}</span> : (item.check_status == "reject") ? <span style={{ color: "red" }}>{"제외"}<br></br><Button
															color="info"
															onClick={() => {
																this.setState({ isShowRejectReasonOpen: true, currentRejectReason: item.reject_reason });
															}}
														>
															사유보기
														</Button></span> : <span style={{ color: "red", fontWeight: "bold" }}>{"[미선택]"}</span>

													}</td>
													<td className="table-cell">
														<Button
															color="info"
															onClick={() => {
																this.updateCheckStatus(item.idx, "accept");
															}}
														>
															수락
														</Button>
														<br></br>
														{
															(item.check_status != "reject") ? (
																<Button
																	color="danger"
																	onClick={() => {
																		this.updateCheckStatus(item.idx, "reject");
																	}}
																>
																	제외
																</Button>) : ""}
														<br></br>
														{/* <Button
															color="warning"
															onClick={() => {
																this.handleReRun(item);
															}}
														>
															Rerun
														</Button> */}
													</td>
												</tr>
											))}
										</tbody>
									</table>


								</div>
							</div>
						</Row>
					</Container>
				</div>
			</Fragment >
		);
	}
}

export default CheckPage;
