import React, { Component, Fragment } from 'react';
import { Map, List, Record } from 'immutable';
import axios from 'axios';
import {
    Table,
    Container, Row, InputGroupText, InputGroup, Col, Button, Form, FormText,

    FormGroup, Label, Input
} from 'reactstrap';
import HeaderComponent from '../header/HeaderComponent'


function statusChange(type) {
    const { data } = this.state;
    this.setState(
        { data: data.set("currentSlamState", type) }
    );
}


class FashionSnapDemo extends Component {
    constructor(props) {
        super(props)
        this.state = {


            email: "noel@reconlabs.ai",
            templateText: "",
            templateDataText: JSON.stringify({ email: "noel@reconlabs.ai" }),



        }
        statusChange = statusChange.bind(this);

        this.test = React.createRef()
    }



    handleSubmit = async () => {

        const fileArray = this.state.files;

        if (this.state.email == "") {
            alert("이메일을 입력해주세요")
            return;
        }


        let cnt = 0;
        const s3SignedUrlData = await axios({
            method: 'Post',
            url: `https://zd7ugect97.execute-api.ap-northeast-2.amazonaws.com/dev/exp`,

            data: {
                email: this.state.email,
                template: this.state.templateText,
                templateData: this.state.templateDataText

            }
        });
        alert("요청 완료")


    }
    onValueChanged = (name, value) => {
        let obj = {};
        obj[name] = value;
        this.setState(obj);
    }
    async componentDidMount() {
        const { data } = this.state;
        function decodeJwtResponse(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        };
        // this.setState(
        //     { data: data.set("email", decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email) }
        // )
        // console.log(this.props.cognito.user.signInUserSession.idToken.jwtToken)
        // console.log(decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email)


    }
    handleOptionChange = (changeEvent) => {
        this.setState({
            genderSelectedOption: changeEvent.target.value
        });
    }
    render() {


        return (
            <Fragment>
                <HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
                <div className="main_contents">
                    <Container fluid>
                        <Row>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                이미지 기반으로 착샷을 만들어드립니다.
                            </div>


                            <div className="col-md-12" id="time_c1">
                                <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>

                                    <Form className="col-md-6">

                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                Email
                                            </Label>
                                            <Col sm={10}>
                                                <Input

                                                    type="text"
                                                    className="form-control"
                                                    placeholder="email"
                                                    value={this.state.email}
                                                    onChange={(val) => {
                                                        this.onValueChanged("email", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >

                                                Template
                                            </Label>
                                            <Col sm={10}>
                                                <textarea

                                                    type="Texarea"
                                                    className="form-control"
                                                    placeholder="템플릿"
                                                    style={{ height: "800px" }}
                                                    value={this.state.templateText}
                                                    onChange={(val) => {
                                                        this.onValueChanged("templateText", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                TemplateData
                                            </Label>
                                            <Col sm={10}>
                                                <textarea

                                                    type="Texarea"
                                                    className="form-control"
                                                    placeholder="템플릿"
                                                    value={this.state.templateDataText}
                                                    onChange={(val) => {
                                                        this.onValueChanged("templateDataText", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>


                                    </Form>

                                </div>

                            </div>
                            <div> <a
                                style={{ fontWeight: 800, color: "red" }}
                            >{this.state.currentPartnum > 0 ? `업로드중 : ${this.state.currentPartnum + 1}/${this.state.totalPartNum}` : ""}</a></div>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <button type="submit" className="btn btn-primary" onClick={() => { this.handleSubmit() }}>
                                    업로드
                                </button>

                            </div>

                        </Row>
                    </Container>
                </div>

            </Fragment >
        );
    }
}

export default FashionSnapDemo;
