import React, { Component, Fragment } from 'react';
import { Map, List, Record } from 'immutable';
import axios from 'axios';
import {
    Table,
    Container, Row, InputGroupText, InputGroup, Col, Button, Form, FormText,

    FormGroup, Label, Input
} from 'reactstrap';
import HeaderComponent from '../header/HeaderComponent'


function statusChange(type) {
    const { data } = this.state;
    this.setState(
        { data: data.set("currentSlamState", type) }
    );
}


class FashionSnapDemo extends Component {
    constructor(props) {
        super(props)
        this.state = {


            genderSelectedOption: "male",

            totalPartNum: 0,
            currentPartnum: 0,
            email: "",
            comment: "",
            total_num: 6,
            ethnicity: "Caucasian",
            age: "Young",


        }
        statusChange = statusChange.bind(this);

        this.test = React.createRef()
    }



    handleSubmit = async () => {

        const fileArray = this.state.files;

        if (this.state.email == "") {
            alert("이메일을 입력해주세요")
            return;
        }

        if (fileArray == undefined || fileArray.length < 1) {
            alert("파일을 선택해주세요");
            return;
        }
        this.setState({ totalPartNum: fileArray.length });
        let cnt = 0;
        for await (const file of fileArray) {

            const s3SignedUrlData = await axios({
                method: 'GET',
                url: `https://zd7ugect97.execute-api.ap-northeast-2.amazonaws.com/dev/file/url`,

                params: {
                    file_size: file.size,
                    org_file_name: file.name,
                    email: this.state.email,
                    comment: this.state.comment,
                    gender: this.state.genderSelectedOption,
                    total_num: this.state.total_num,
                    ethnicity: this.state.ethnicity,
                    age: this.state.age
                }
            });
            console.log(s3SignedUrlData)
            const url = s3SignedUrlData.data.url;

            const _axios = axios.create()
            delete _axios.defaults.headers.put['Content-Type']

            await _axios.put(url, file);
            this.setState({ currentPartnum: cnt++ });
        }
        alert("업로드 완료")


    }
    onValueChanged = (name, value) => {
        let obj = {};
        obj[name] = value;
        this.setState(obj);
    }
    async componentDidMount() {
        const { data } = this.state;
        function decodeJwtResponse(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        };
        // this.setState(
        //     { data: data.set("email", decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email) }
        // )
        // console.log(this.props.cognito.user.signInUserSession.idToken.jwtToken)
        // console.log(decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email)


    }
    handleOptionChange = (changeEvent) => {
        this.setState({
            genderSelectedOption: changeEvent.target.value
        });
    }
    render() {


        return (
            <Fragment>
                <HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
                <div className="main_contents">
                    <Container fluid>
                        <Row>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                이미지 기반으로 착샷을 만들어드립니다.
                            </div>


                            <div className="col-md-12" id="time_c1">
                                <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>

                                    <Form className="col-md-6">
                                        <FormGroup row>
                                            <Label
                                                for="exampleFile"
                                                sm={2}
                                            >
                                                File
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    id="exampleFile"
                                                    multiple={true}
                                                    name="file"
                                                    type="file"
                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        this.setState({ files: files });
                                                    }}
                                                />
                                                <FormText>
                                                    이미지 파일을 올려주세요
                                                </FormText>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                Email
                                            </Label>
                                            <Col sm={10}>
                                                <Input

                                                    type="text"
                                                    className="form-control"
                                                    placeholder="email"
                                                    value={this.state.email}
                                                    onChange={(val) => {
                                                        this.onValueChanged("email", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                Comment
                                            </Label>
                                            <Col sm={10}>
                                                <Input

                                                    type="text"
                                                    className="form-control"
                                                    placeholder="comment"
                                                    value={this.state.comment}
                                                    onChange={(val) => {
                                                        this.onValueChanged("comment", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                인종
                                            </Label>
                                            <Col sm={10}>
                                                <Input value={this.state.ethnicity} onChange={(e) => this.onValueChanged("ethnicity", e.target.value)} id="ethnicity" name="ethnicity" type="select">


                                                    <option value={"South Korean"}>한국인</option>
                                                    <option value={"Caucasian"}>백인</option>
                                                    <option value={"African American"}>흑인</option>
                                                    <option value={"Middle East Asian"}>아랍인</option>



                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                나이
                                            </Label>
                                            <Col sm={10}>
                                                <Input value={this.state.age} onChange={(e) => this.onValueChanged("age", e.target.value)} id="age" name="age" type="select">


                                                    <option value={"Young"}>젊은</option>
                                                    <option value={"Mid Age"}>중년</option>
                                                    <option value={"Elderly"}>노년</option>
                                                    <option value={"Baby"}>아기</option>


                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                요청 숫자
                                            </Label>
                                            <Col sm={10}>
                                                <Input

                                                    type="text"
                                                    className="form-control"
                                                    placeholder="total_num"
                                                    value={this.state.total_num}
                                                    onChange={(val) => {
                                                        this.onValueChanged("total_num", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>






                                        <FormGroup row>
                                            <Label
                                                for="engine_type"
                                                sm={2}
                                            >
                                                성별
                                            </Label>
                                            <Col sm={10}>
                                                <br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="female"
                                                        checked={this.state.genderSelectedOption === 'female'}
                                                        onChange={this.handleOptionChange}
                                                    />
                                                    Female
                                                </label>
                                                <br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="male"
                                                        checked={this.state.genderSelectedOption === 'male'}
                                                        onChange={this.handleOptionChange}
                                                    />
                                                    Male
                                                </label>
                                            </Col>
                                        </FormGroup>

                                    </Form>

                                </div>

                            </div>
                            <div> <a
                                style={{ fontWeight: 800, color: "red" }}
                            >{this.state.currentPartnum > 0 ? `업로드중 : ${this.state.currentPartnum + 1}/${this.state.totalPartNum}` : ""}</a></div>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <button type="submit" className="btn btn-primary" onClick={() => { this.handleSubmit() }}>
                                    업로드
                                </button>

                            </div>

                        </Row>
                    </Container>
                </div>

            </Fragment >
        );
    }
}

export default FashionSnapDemo;
