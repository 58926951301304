import React, { Component, Fragment } from 'react';

import Immutable from 'immutable';

import { Map, List, Record } from 'immutable';


import { BrowserRouter, Routes, Route } from "react-router-dom";

import AllPage from './pages/AllPage'
import CheckPage from './pages/CheckPage'
import AIHistoryCheck from './pages/AIHistoryCheck'
import AITaskCheck from './pages/AITaskCheck'
import CheckPage2 from './pages/CheckPage2'


import CheckPageFailed from './pages/CheckPageFailed'
import CSVImport from './pages/CSVImport'

import Fashionsnap from './pages/fashionsnap';
import ProjectPage from './pages/ProjectPage';
import FashionSnapDemo from './pages/FashionSnapDemo';
import ProductCheckPage from './pages/ProductCheckPage';
import ProductCheckPageOne from './pages/ProductCheckPageOne';

import FashionSnapEmailDemo from './pages/FashionSnapEmailDemo';


class MainComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({
                contact: List()
            })


        }
    }
    componentDidMount() {

    }

    render() {

        return (

            <BrowserRouter>

                <Routes>
                    <Route path="/fashionsnap" element={<Fashionsnap cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route exact path="/" element={<AllPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/check" element={<CheckPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/csv" element={<CSVImport cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />

                    <Route path="/project" element={<ProjectPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />



                    <Route path="/fsdemo" element={<FashionSnapDemo cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/check2" element={<CheckPage2 cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/failed" element={<CheckPageFailed cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/aihistorycheck" element={<AIHistoryCheck cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />

                    <Route path="/product" element={<ProductCheckPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/productone" element={<ProductCheckPageOne cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/fsemail" element={<FashionSnapEmailDemo cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />




                </Routes>

            </BrowserRouter>

        );
    }
}

export default MainComponent;
