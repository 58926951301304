import React, { Component } from 'react';

import { Map, List, Record } from 'immutable';

import {
    Input, Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import logo from "../../images/reconlabs_logo.png";

class HeaderComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({

            })


        }
    }
    componentDidMount() {
        const { data } = this.state;
        this.setState(
            { data: data.set("stage", this.props.stage) }
        )
    }
    onValueChanged = (name, value) => {
        const { data } = this.state;
        const that = this;
        const setState = this.props.setStage;

        //console.log("name:", name, ",value:", value)

        this.setState(
            { data: data.set(name, value) }, () => {
                //console.log(that.props);
                that.props.setStage(value);


            }
        )



    }
    render() {
        const { data } = this.state;
        return (

            <Navbar color="faded" light expand="md" className="navbar  navbar-expand-lg navbar-dark nav_menu">

                <img src={logo} className="header_logo" alt="Logo" style={{ "marginRight": "10px" }} />
                <NavbarBrand href="/" style={{ fontWeight: 800 }}>
                    Fashionsnap
                </NavbarBrand>
                <Nav className="mr-auto" navbar>


                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="font-weight-bold">
                            목록
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem href="/">Task 목록</DropdownItem>
                            <DropdownItem href="/project">프로젝트 목록</DropdownItem>
                            {/* <DropdownItem divider />



                            <DropdownItem divider /> */}




                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="font-weight-bold">
                            업로드
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem href="/fashionsnap">직접 업로드</DropdownItem>
                            <DropdownItem href="/csv">csv업로드</DropdownItem>
                            <DropdownItem href="/fsdemo">fsdemo</DropdownItem>
                            <DropdownItem href="/fsemail">fsemail</DropdownItem>

                            {/* <DropdownItem href="/aihistorycheck">aihistorycheck</DropdownItem>
                            <DropdownItem href="/aitaskcheck">aitaskcheck</DropdownItem> */}
                            {/* <DropdownItem divider />



                            <DropdownItem divider /> */}




                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="font-weight-bold">
                            검수
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem href="/check">전체 검수</DropdownItem>
                            <DropdownItem href="/check2">개별 검수</DropdownItem>
                            <DropdownItem href="/product">프로덕트 검수</DropdownItem>
                            <DropdownItem href="/productone">개별 프로덕트 검수</DropdownItem>


                            <DropdownItem divider />
                            <DropdownItem href="/failed">성공/실패 목록</DropdownItem>






                        </DropdownMenu>
                    </UncontrolledDropdown>

                </Nav>

                <Nav navbar>


                    <NavItem className="d-flex align-items-center">
                        <NavLink className="font-weight-bold" href="#" onClick={this.props.signOut}>로그 아웃</NavLink>
                    </NavItem>


                </Nav>
            </Navbar>


        );
    }
}

export default HeaderComponent;
