import React, { Component, Fragment } from 'react';
import Papa from "papaparse";
import { Map, List, Record } from 'immutable';
import axios from 'axios';
import {
    Table,
    Container, Row, InputGroupText, InputGroup, Col, Button, Form, FormText,

    FormGroup, Label, Input
} from 'reactstrap';
import HeaderComponent from '../header/HeaderComponent'

const defaultFalPrompts = [
    "realistic, A professional studio photoshoot featuring a South Korean female model showcasing clothing. The model has a hairstyle and facial features reminiscent of movie star, characterized by stylish, well-groomed hair and a youthful face. . The overall vibe of the photo is fashionable and polished, highlighting the clothing's fit and style in a high-quality, studio-lit environment.",
    "realistic, A professional studio photoshoot featuring a South Korean male model showcasing clothing. The model has a hairstyle and facial features reminiscent of movie star, characterized by stylish,youthful face. . The overall vibe of the photo is fashionable and polished, highlighting the clothing's fit and style in a high-quality, studio-lit environment."
]
const defaultPrompts = [
    `System Prompt (V1 Only with Detailed Clothing Analysis, Image Generation Focus, RGB Color Codes, Texture, and Color Coordination)
    
            You are a GPT designed to generate a fashion photoshoot prompt based on a clothing item's description or image. You will create a simple studio shoot (Version 1) where the primary focus is on the clothing's form, fit, details, texture, and color.The result will be a single, clear, and concise prompt designed for generating an image of the described fashion shoot.

            For each clothing item provided, you must carefully analyze the following:

    Clothing Length:

    For Tops and Outerwear(e.g., jackets): Describe whether the length of the garment reaches the midriff(above the navel), waist(just at the waistline), hips(mid- hip), or pelvis(below the hips but above the thighs).For sleeves, specify whether they are short(above the elbow), three - quarter length(mid - forearm), or full - length(reaching the wrist).

            Neckline and Collar Shape: Clearly describe the neckline and collar type.Specify whether it is a V - neck, round neck, turtleneck, or collared shirt.If there is a collar, describe whether it is flat, stand - up, folded, or lapel style(for jackets and outerwear).For example, "the jacket has a wide lapel collar with a slightly pointed shape."

            Button and Zipper Details: For outerwear and tops, provide details about any fastenings such as buttons or zippers, including the number, shape(e.g., round, square), material(e.g., plastic, metal), and position.Indicate whether they are purely decorative or functional, and specify their placement, such as centered down the front, on the cuffs, or on the pockets.

            For Dresses: Specify whether the dress is mini(above the knee), knee - length(just at or slightly above the knee), midi(below the knee to mid - calf), or maxi(ankle - length or floor - length).Additionally, describe the sleeve length and overall fit to ensure the correct silhouette is represented.

            For Bottoms: Clarify if the bottoms are shorts(above mid - thigh), cropped pants(ending just below the knee or at mid - calf), ankle - length(just above the ankle), or full - length(reaching the feet).Additionally, describe the rise of the waistband, whether it is low - rise(below the hips), mid - rise(around the navel), or high - rise(above the navel).

            Tightness and Fit: Describe how the clothing fits the body, whether it is tight, loose, or tailored.For example, specify if a jacket is tailored at the waist or if the sleeves are fitted, and if pants are slim - fit or wide - legged.

            Clothing Shape and Silhouette: Reflect the overall shape of the garment.Highlight if it creates an A - line, boxy, tailored, or flowing silhouette, and ensure that the model's pose and lighting bring out these features.

            Texture and Material: Describe the texture and thickness of the fabric.Specify if the material is lightweight, heavy, sheer, or thick.Also, describe the texture, such as smooth, rough, soft, coarse, silky, or textured.For example, "the blouse is made of a lightweight, silky fabric with a smooth texture," or "the jacket is crafted from a thick wool material with a rough surface."

            Details and Design Elements: Pay close attention to any distinctive design elements like seams, hemlines, waistbands, lapels, or unique cuts, and ensure they are visible in the photoshoot.For outerwear, include details about pockets, shoulder pads, and cuffs if applicable.

            Clothing Color(in RGB Code): Identify and specify the exact RGB color code of the garment.For example, if the jacket is navy blue, use its corresponding RGB code(e.g., "The jacket is a deep navy color (RGB: 0, 0, 128)") to ensure accuracy in color representation.

            Color Coordination: Ensure that other clothing items or accessories worn by the model are in neutral or complementary colors to make the target garment stand out.For instance, if the jacket is navy blue, the bottoms and accessories should be in muted or neutral tones(e.g., black, white, beige, etc.) to keep focus on the primary garment.

            Photoshoot Characteristics:

            The model will face the camera directly, fully showcasing the clothing.
            Poses should be natural but carefully chosen to display the clothing’s key features, including the garment's fit, texture, and shape on different parts of the body (e.g., arms, legs, waist).
            In particular, you should set your pose so that you do not put your hands in your pockets.
            For bottoms or one - piece dresses, the frame must be set so that the entire body can be seen.
            The lighting should be bright and even, ensuring that the details of the garment are clear and free from shadows.
            If the item is a bottom, outerwear, or dress, the model’s full body must be visible to showcase the garment’s entire length.
            Accessories should be minimal and complement the clothing without overshadowing it.
            The default model is a young, slim, beautiful South Korean female, but you will adjust this if specific model characteristics are provided.
            Important Instruction: Return only the prompt for generating the image based on the analysis above.Include the RGB color code for the clothing item, and describe its texture and thickness.Adjust other clothing colors to ensure the target garment stands out.Do not provide any explanation, just the prompt.`,
    `System Prompt (V1 Only with Detailed Clothing Analysis, Image Generation Focus, RGB Color Codes, Texture, and Color Coordination)
    
            You are a GPT designed to generate a fashion photoshoot prompt based on a clothing item's description or image. You will create a simple studio shoot (Version 1) where the primary focus is on the clothing's form, fit, details, texture, and color.The result will be a single, clear, and concise prompt designed for generating an image of the described fashion shoot.

            For each clothing item provided, you must carefully analyze the following:

    Clothing Length:

    For Tops and Outerwear(e.g., jackets): Describe whether the length of the garment reaches the midriff(above the navel), waist(just at the waistline), hips(mid- hip), or pelvis(below the hips but above the thighs).For sleeves, specify whether they are short(above the elbow), three - quarter length(mid - forearm), or full - length(reaching the wrist).

            Neckline and Collar Shape: Clearly describe the neckline and collar type.Specify whether it is a V - neck, round neck, turtleneck, or collared shirt.If there is a collar, describe whether it is flat, stand - up, folded, or lapel style(for jackets and outerwear).For example, "the jacket has a wide lapel collar with a slightly pointed shape."

            Button and Zipper Details: For outerwear and tops, provide details about any fastenings such as buttons or zippers, including the number, shape(e.g., round, square), material(e.g., plastic, metal), and position.Indicate whether they are purely decorative or functional, and specify their placement, such as centered down the front, on the cuffs, or on the pockets.

            For Dresses: Specify whether the dress is mini(above the knee), knee - length(just at or slightly above the knee), midi(below the knee to mid - calf), or maxi(ankle - length or floor - length).Additionally, describe the sleeve length and overall fit to ensure the correct silhouette is represented.

            For Bottoms: Clarify if the bottoms are shorts(above mid - thigh), cropped pants(ending just below the knee or at mid - calf), ankle - length(just above the ankle), or full - length(reaching the feet).Additionally, describe the rise of the waistband, whether it is low - rise(below the hips), mid - rise(around the navel), or high - rise(above the navel).

            Tightness and Fit: Describe how the clothing fits the body, whether it is tight, loose, or tailored.For example, specify if a jacket is tailored at the waist or if the sleeves are fitted, and if pants are slim - fit or wide - legged.

            Clothing Shape and Silhouette: Reflect the overall shape of the garment.Highlight if it creates an A - line, boxy, tailored, or flowing silhouette, and ensure that the model's pose and lighting bring out these features.

            Texture and Material: Describe the texture and thickness of the fabric.Specify if the material is lightweight, heavy, sheer, or thick.Also, describe the texture, such as smooth, rough, soft, coarse, silky, or textured.For example, "the blouse is made of a lightweight, silky fabric with a smooth texture," or "the jacket is crafted from a thick wool material with a rough surface."

            Details and Design Elements: Pay close attention to any distinctive design elements like seams, hemlines, waistbands, lapels, or unique cuts, and ensure they are visible in the photoshoot.For outerwear, include details about pockets, shoulder pads, and cuffs if applicable.

            Clothing Color(in RGB Code): Identify and specify the exact RGB color code of the garment.For example, if the jacket is navy blue, use its corresponding RGB code(e.g., "The jacket is a deep navy color (RGB: 0, 0, 128)") to ensure accuracy in color representation.

            Color Coordination: Ensure that other clothing items or accessories worn by the model are in neutral or complementary colors to make the target garment stand out.For instance, if the jacket is navy blue, the bottoms and accessories should be in muted or neutral tones(e.g., black, white, beige, etc.) to keep focus on the primary garment.

            Photoshoot Characteristics:

            The model will face the camera directly, fully showcasing the clothing.
            Poses should be natural but carefully chosen to display the clothing’s key features, including the garment's fit, texture, and shape on different parts of the body (e.g., arms, legs, waist).
            In particular, you should set your pose so that you do not put your hands in your pockets.
            For bottoms or one - piece dresses, the frame must be set so that the entire body can be seen.
            The lighting should be bright and even, ensuring that the details of the garment are clear and free from shadows.
            If the item is a bottom, outerwear, or dress, the model’s full body must be visible to showcase the garment’s entire length.
            Accessories should be minimal and complement the clothing without overshadowing it.
            The default model is a young, South Korean male, but you will adjust this if specific model characteristics are provided.
            Important Instruction: Return only the prompt for generating the image based on the analysis above.Include the RGB color code for the clothing item, and describe its texture and thickness.Adjust other clothing colors to ensure the target garment stands out.Do not provide any explanation, just the prompt.`
]


function statusChange(type) {
    const { data } = this.state;
    this.setState(
        { data: data.set("currentSlamState", type) }
    );
}


class CSVImport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customer_name: "",
            user_id: "",
            cfg: 3.5,
            height: 1536,
            width: 1536,
            step: 50,
            genderSelectedOption: "female",
            project_name: "mtest",
            falprompt: defaultFalPrompts[0],
            prompt: defaultPrompts[0],
            totalPartNum: 0,
            currentPartnum: 0,

            product_id: "",
            category1: "top",
            category2: "blouse",

        }
        statusChange = statusChange.bind(this);

        this.test = React.createRef()
    }



    handleSubmit = async () => {

        const fileArray = this.state.files;
        const prompt = this.state.prompt;
        const cfg = this.state.cfg;
        const height = this.state.height;
        const width = this.state.width;
        const project_name = this.state.project_name;
        const falprompt = this.state.falprompt;
        const customer_name = this.state.customer_name;
        if (fileArray.length < 1) {
            alert("파일을 선택해주세요");
            return;
        }
        this.setState({ totalPartNum: fileArray.length });
        let cnt = 0;
        for await (const file of fileArray) {

            const s3SignedUrlData = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/task/singlepart`,

                params: {
                    size: file.size,
                    org_file_name: file.name,
                    prompt: prompt,
                    cfg: cfg,
                    height: height,
                    width: width,
                    falprompt: falprompt,
                    customer_name: customer_name,
                    project_name: project_name
                }
            });
            console.log(s3SignedUrlData)
            const url = s3SignedUrlData.data.url;

            const _axios = axios.create()
            delete _axios.defaults.headers.put['Content-Type']

            await _axios.put(url, file);
            this.setState({ currentPartnum: cnt++ });
        }
        alert("업로드 완료")


    }
    onValueChanged = (name, value) => {
        let obj = {};
        obj[name] = value;
        this.setState(obj);
    }
    async componentDidMount() {
        const { data } = this.state;
        function decodeJwtResponse(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        };
        // this.setState(
        //     { data: data.set("email", decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email) }
        // )
        // console.log(this.props.cognito.user.signInUserSession.idToken.jwtToken)
        // console.log(decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email)

    }

    handleCsvUpload = async () => {

        function extractInsideCurlyBrackets(input) {
            if (input == undefined) {
                return null;
            }
            // Use regular expression to match content inside curly brackets
            const regex = /\{([^}]+)\}/;
            const match = input.match(regex);

            // If a match is found, return the content; otherwise, return null
            return match ? match[1] : null;
        }

        const csvFile = this.state.files[0];

        if (!this.state.customer_name || this.state.customer_name.length < 1) {
            alert("고객사 이름을 입력해주세요");
            return;
        }
        Papa.parse(csvFile, {
            header: true, // If your CSV has headers
            complete: async (results) => {
                const data = results.data;
                let totalParts = data.length;
                this.setState({ totalPartNum: totalParts });

                let cnt = 0;
                let arr = [];
                for (const row of data) {
                    const product_id = row["id"];
                    const project_name = row["project"];
                    const category_1 = row["category"];
                    const url = row["url"];
                    if (!url) {
                        continue;
                    }
                    const str = row["sizes"].replaceAll('\'', '"');
                    const _sizeInfo = JSON.parse(str);
                    const sizeInfo = _sizeInfo[category_1]
                    let hip = undefined
                    let waist = undefined
                    let breast = undefined
                    let sleeve = undefined
                    let shoulder = undefined
                    let total = undefined
                    if (sizeInfo != undefined) {


                        hip = sizeInfo["hip"];
                        waist = sizeInfo["waist"];
                        breast = sizeInfo["breast"];
                        sleeve = sizeInfo["sleeve"];
                        shoulder = sizeInfo["shoulder"];
                        total = sizeInfo["total"];
                    }
                    const sizeGroup = extractInsideCurlyBrackets(row["dp_size_groups"])
                    if (product_id && project_name && url) {
                        arr.push({
                            product_id, project_name, category_1, url,

                            size_hip: hip,
                            size_waist: waist,
                            size_breast: breast,
                            size_sleeve: sleeve,
                            size_shoulder: shoulder,
                            size_total: total,
                            size_group: sizeGroup,

                        })
                    }
                    try {





                        this.setState({ currentPartnum: cnt++ });
                    } catch (error) {
                        console.error(`Failed to upload file from ${url}:`, error);
                    }
                }
                try {

                    await axios({
                        method: "Put",
                        url: `${process.env.REACT_APP_CSV_PUTLARGE_PATH}`,
                        data: {




                            prompt: this.state.prompt, // Assuming the same prompt for all
                            cfg: this.state.cfg,
                            height: this.state.height,
                            width: this.state.width,
                            falprompt: this.state.falprompt,
                            customer_name: this.state.customer_name,
                            input_data: JSON.stringify(arr),
                        },
                    });
                    alert("CSV 파일 업로드 완료");
                } catch (e) {
                    alert("업로드 실패", e);
                }

            },
            error: (error) => {
                console.error("Error parsing CSV file:", error);
            },
        });
    };
    render() {


        return (
            <Fragment>
                <HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
                <div className="main_contents">
                    <Container fluid>
                        <Row>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                CSV 벌크 업로드입니다. 프로젝트 명,product_id,파일명 등은 CSV파일을 참고합니다. 반드시 고객사 이름을 확인해주세요.
                            </div>


                            <div className="col-md-12" id="time_c1">
                                <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>

                                    <Form className="col-md-6">
                                        <FormGroup row>
                                            <Label
                                                for="exampleFile"
                                                sm={2}
                                            >
                                                File
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    id="exampleFile"

                                                    name="file"
                                                    type="file"
                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        this.setState({ files: files });
                                                    }}
                                                />
                                                <FormText>
                                                    이미지 파일을 올려주세요
                                                </FormText>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}
                                            >
                                                고개사 이름(영문)
                                            </Label>
                                            <Col sm={10}>
                                                <Input

                                                    type="text"
                                                    className="form-control"
                                                    placeholder="customer_name"
                                                    value={this.state.customer_name}
                                                    onChange={(val) => {
                                                        this.onValueChanged("customer_name", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>





                                        <FormGroup row>
                                            <Label
                                                for="comment"
                                                sm={2}
                                            >
                                                step
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="step"
                                                    value={this.state.step}
                                                    onChange={(val) => {
                                                        this.onValueChanged("step", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="width"
                                                sm={2}
                                            >
                                                width
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="width"
                                                    value={this.state.width}
                                                    onChange={(val) => {
                                                        this.onValueChanged("width", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="prompt"
                                                sm={2}
                                            >
                                                height
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="height"
                                                    value={this.state.height}
                                                    onChange={(val) => {
                                                        this.onValueChanged("height", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="cfg"
                                                sm={2}
                                            >
                                                cfg
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="directory"
                                                    value={this.state.cfg}
                                                    onChange={(val) => {
                                                        this.onValueChanged("cfg", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="engine_type"
                                                sm={2}
                                            >
                                                Default Prompt Gender
                                            </Label>
                                            <Col sm={10}>
                                                <br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="female"
                                                        checked={this.state.genderSelectedOption === 'female'}
                                                        onChange={this.handleOptionChange}
                                                    />
                                                    Female
                                                </label>
                                                <br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="male"
                                                        checked={this.state.genderSelectedOption === 'male'}
                                                        onChange={this.handleOptionChange}
                                                    />
                                                    Male
                                                </label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="cfg"
                                                sm={2}
                                            >
                                                prompt
                                            </Label>
                                            <Col sm={10}>
                                                <textarea
                                                    style={{ height: "250px" }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="prompt"
                                                    value={this.state.prompt}
                                                    onChange={(val) => {
                                                        this.onValueChanged("prompt", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="cfg"
                                                sm={2}
                                            >
                                                fal prompt
                                            </Label>
                                            <Col sm={10}>
                                                <textarea
                                                    style={{ height: "250px" }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="fal prompt"
                                                    value={this.state.falprompt}
                                                    onChange={(val) => {
                                                        this.onValueChanged("falprompt", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>

                                </div>

                            </div>
                            <div> <a
                                style={{ fontWeight: 800, color: "red" }}
                            >{this.state.currentPartnum > 0 ? `업로드중 : ${this.state.currentPartnum + 1}/${this.state.totalPartNum}` : ""}</a></div>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <button type="submit" className="btn btn-primary" onClick={() => { this.handleCsvUpload() }}>
                                    업로드
                                </button>

                            </div>

                        </Row>
                    </Container>
                </div>

            </Fragment >
        );
    }
}

export default CSVImport;
