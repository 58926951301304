import React, { Component, Fragment } from "react";

import Immutable, { is } from "immutable";

import * as XLSX from 'xlsx';

import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";
import TagComponent from "../element/TagComponent";

import '../../css/ImageTable.css';
const { Base64 } = require("js-base64");


class CheckPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_name: "mtest",
			data: [],
			project_list: [],
			selectedProjectName: "",
			isModalOpen: false,
			modalImageSrc: '',
			isRejctModelOpen: false,
			rejectTargetIdx: undefined,
			rejectReason: "",
			currentRejectReason: "",
			excludeEvaluated: true,
			onDownloadAll: false,
			currentDownloadUrl: "",
			taskTags: [],
			check_stat: {
				rejected: 0,
				accepted: 0,
				undefined: 0
			}

		};

		this.modalRef = React.createRef();
		window.that = this;
	}







	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};


	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}


	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getProjectList();
		//this.getImageData();

	}


	getProjectList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/list`,
			params: {


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const projects = JSON.parse(result.data.body).project_list;

		this.setState({ project_list: projects, selectedProjectName: projects.unshift({ project_name: "프로젝트 선택" }) }, () => {

			this.getImageData();

		});
	}
	getImageData = async () => {
		if (this.state.selectedProjectName == "") {
			return;
		}
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/task`,
			params: {
				project_name: this.state.selectedProjectName,

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tasks = JSON.parse(result.data.body).tasks;
		console.log(JSON.parse(result.data.body).tasks)

		//if task item has discard field and if its true, set discarded to true
		tasks.forEach((task) => {
			if (task.discard && task.discard == "true") {
				task.discarded = true;
			}
		});
		const checkStat = JSON.parse(result.data.body).check_status;

		let rejected = 0;
		let accepted = 0;
		let undefined = 0;
		for (const checkStatus of checkStat) {
			if (checkStatus.check_status == "reject") {
				rejected += checkStatus.count;
			} else if (checkStatus.check_status == "accept") {
				accepted += checkStatus.count;
			}
			else {
				undefined += checkStatus.count;
			}

		}

		//set to state
		this.setState({ data: tasks, check_stat: { rejected: rejected, accepted: accepted, undefined: undefined } });
	}
	generateImageUrl(item) {

		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=input`
	}

	generateModelUrl(item) {
		if (item.status != "succeed") {
			return "-";
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=model`
	}

	generateResultUrl(item) {

		if (item.status != "succeed") {
			return "-";
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output`
	}
	updateCheckStatus = async (idx, status) => {
		if (status == "reject") {
			this.setState({ isRejctModelOpen: true, rejectTargetIdx: idx });
			return;
		}
		let targetSeq = undefined
		let discardStatus = undefined
		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === idx) {
					targetSeq = item.seq

					return {
						...item,
						check_status: status,
					}
				}
				return item;

			}
			)
		}), async () => {

			console.log(targetSeq, discardStatus)
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task`,
				data: {
					seq: targetSeq,
					check_status: status
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});

		});
	};
	updateRejectStatus = async (idx, status) => {

		let targetSeq = undefined
		let discardStatus = undefined
		let rejectReason = this.state.rejectReason
		if (rejectReason == "") {
			alert("제외 사유를 입력해주세요")
			return;
		}
		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === idx) {
					targetSeq = item.seq

					return {
						...item,
						check_status: status,
						reject_reason: rejectReason,
					}
				}
				return item;

			},


			),
			isRejctModelOpen: false,
			rejectReason: ""
		}), async () => {

			console.log(targetSeq, discardStatus)
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task`,
				data: {
					seq: targetSeq,
					check_status: status,
					reject_reason: rejectReason,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});

		});
	};
	// Open modal if Ctrl is pressed when clicking on result image
	handleResultClick = (e, item, type) => {

		let url = "";
		if (type == "model") {
			url = this.generateModelUrl(item)
		} else if (type == "result") {
			url = this.generateResultUrl(item)
		}
		else {
			url = this.generateImageUrl(item)
		}
		if (!e.ctrlKey && !e.metaKey) {
			this.setState({ isModalOpen: true, modalImageSrc: url });
		}
	};

	// Close the modal
	closeModal = () => {
		this.setState({ isModalOpen: false, modalImageSrc: '', isRejctModelOpen: false, rejectTargetIdx: undefined, isShowRejectReasonOpen: false });
	};
	downloadAll = async () => {

		if (this.state.onDownloadAll) {
			window.open(this.state.currentDownloadUrl, '_blank')
			return;
		}

		this.setState({ onDownloadAll: true })
		try {
			const downloadResult = await axios({
				method: 'GET',
				url: `https://datk6bheyj2ja4caumaiet75lu0wmywd.lambda-url.ap-northeast-2.on.aws/`,
				params: {
					project_name: this.state.selectedProjectName,
					check_status: "accept"

				}

			});
			const url = JSON.parse(downloadResult.data.body).downloadUrl;
			console.log(url);
			//window.open(url, '_blank')
			this.setState({ currentDownloadUrl: url })
			//this.setState({ onDownloadAll: false })
		}
		catch (e) {
			alert("파일 키가 없거나 올바른 요청이 아닙니다.")
			console.log(e)
		}

	};
	rerunAll = async () => {
		//confirm user
		if (!window.confirm("모든 실패한 항목을 재실행하시겠습니까?")) {
			return;
		}


		try {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task/rerunAll`,
				params: {
					project_name: this.state.selectedProjectName,
					check_status: "reject"
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			}); alert("모든 실패한 항목이 재실행되었습니다.")
		} catch (e) {
			alert("실패한 항목이 없거나 재실행에 실패했습니다.")
		}

	}
	exportToExcel = () => {

		const filteredData = this.state.data.filter(item => {
			return item.check_status == "accept";
		});


		const rawdata = filteredData.map(item => {
			const { idx, seq, discard, category_1, product_id, ...rest } = item;  // Remove the discarded property
			return {

				id: product_id,
				...rest,

				category: category_1,
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public?seq=${item.seq}` // Custom column based on seq
			};
		});




		// Convert updated data to sheet
		const ws = XLSX.utils.json_to_sheet(rawdata);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Data");

		// Export as Excel file
		XLSX.writeFile(wb, `fssnap_${this.state.selectedProjectName}_${moment().format("YYYY_MM_DD_HH_mm")}.xlsx`);
	};
	handleReRun = async (item) => {

		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/task/rerun`,
			params: {
				seq: item.seq

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		//clear data that has same seq
		this.setState((prevState) => ({
			data: prevState.data.map((_item) => {


				if (item.seq === _item.seq) {
					let nitem = _item
					nitem.status = "created";
					return {
						...nitem,
						check_status: undefined,
					}
				}
				return _item;

			}
			)
		}));
	};
	onValueChanged = (name, value) => {

		console.log("name:", name, ",value:", value)

		this.setState({
			[name]: value

		}, () => {
			if (name == "selectedProjectName") {
				this.getImageData();
			}
		});

	};
	toggleExcludeEvaluated = () => {
		// Toggle excludeEvaluated state
		this.setState((prevState) => ({
			excludeEvaluated: !prevState.excludeEvaluated
		}));
	};
	getTaskTagList = async (task_idx) => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/task/tag`,
			params: {
				task_idx: task_idx
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tag_list = JSON.parse(result.data.body).tag_list
		//sort tag list by alphabet order
		tag_list.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		console.log(tag_list);
		this.setState({ taskTags: tag_list }, () => {


		});
	}
	render() {
		let filteredData = this.state.data;
		if (this.state.excludeEvaluated) {
			filteredData = this.state.data.filter(item => item.check_status == "undefined");
		}

		return (
			<Fragment>
				{this.state.isModalOpen && (
					<div className="modals">
						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<img style={{
								display: "block",
								"margin-left": "auto",
								"margin-right": "auto",
								"object-fit": "contain",
								"max-height": 800
							}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
						</div>
					</div>)}

				{this.state.isRejctModelOpen && (
					<div className="modals">

						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<Row>
								<Label
									for="exampleFile"
									sm={2}
								>
									제외 사유
								</Label>
								<textarea
									style={{ height: "250px" }}
									type="text"
									className="form-control"
									placeholder="제외 사유를 입력해주세요"
									value={this.state.rejectReason}
									onChange={(val) => {
										this.onValueChanged("rejectReason", val.target.value);
									}}
								/>
							</Row>
							<Row>

								<Button
									color="danger"

									onClick={() => {
										this.updateRejectStatus(this.state.rejectTargetIdx, "reject");
									}}
								>
									제외
								</Button>
							</Row>
						</div>
					</div>)}
				{this.state.isShowRejectReasonOpen && (
					<div className="modals">

						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<Row>
								<Col md={12}>


									{
										this.state.taskTags.map((item, index) => (
											<TagComponent name={item.name} hideClose={true} onRemove={() => {

											}}></TagComponent>
										))
									}

								</Col><Col md={9}>
									<Label
										for="exampleFile"
										sm={2}
									>
										제외 사유
									</Label>
									<textarea
										style={{ height: "250px" }}
										type="text"
										disabled={true}
										className="form-control"
										placeholder=""
										value={this.state.currentRejectReason}

									/>
								</Col>
							</Row>

						</div>
					</div>)}
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>

									<Col md={2}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.getImageData();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={3}>
										<ButtonGroup>
											<Button
												color="primary"
												outline
												onClick={() => {
													this.toggleExcludeEvaluated();
												}}
												active={this.state.excludeEvaluated}
											>
												검수된 항목 제외
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={3}>
										<ButtonGroup>
											<Button
												color="primary"
												outline
												onClick={() => {
													this.rerunAll();
												}}

											>
												제외된 항목 모두 Rerun
											</Button>
										</ButtonGroup>
									</Col>
								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{`검수(수락:${this.state.check_stat.accepted}, 제외:${this.state.check_stat.rejected}, 미선택:${this.state.check_stat.undefined})`}
								</Col>
								<Col md={4}>

									<Col md={3}>
										<ButtonGroup>
											<Button
												color="primary"
												onClick={() => {
													this.exportToExcel();
												}}
											>
												Export(Excel)
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={3}>
										<ButtonGroup>
											<Button
												color={(this.state.onDownloadAll != true) ? "primary" : (this.state.currentDownloadUrl) ? "primary" : "secondary"}
												disabled={(this.state.onDownloadAll != true) ? false : (this.state.currentDownloadUrl) ? false : true}
												onClick={() => {
													this.downloadAll();
												}}
											>
												{(this.state.onDownloadAll != true) ? "전체 다운로드" : (this.state.currentDownloadUrl) ? "다운로드 하려면 눌러주세요" : "다운로드 준비 중"}
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={2}>
										프로젝트 선택:
									</Col>
									<Col md={4}>
										<Input value={this.state.selectedProjectName} onChange={(e) => this.onValueChanged("selectedProjectName", e.target.value)} id="selectedProjectName" name="selectedProjectName" type="select">

											{this.state.project_list.map((item) => (
												<option value={item.project_name}>{item.project_name}</option>
											)
											)}


										</Input>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<div className="table-container">
									<table>
										<thead>
											<tr>
												<th>Idx</th>
												<th>Product_id</th>
												<th >Filename</th>
												<th>Status</th>
												<th>Original Image</th>
												<th>Model</th>
												<th>Result</th>
												<th>검수상태</th>
												<th>Control</th>
											</tr>
										</thead>
										<tbody>
											{filteredData.map((item) => (
												<tr
													key={item.idx}

													className={item.check_status == "reject" ? 'discarded-row' : ''}
												>
													<td className="table-cell">{item.idx}</td>
													<td className="table-cell">{item.product_id}</td>
													<td className="table-cell" >{item.org_file_name}</td>
													<td className="table-cell">
														{
															item.status == "succeed" ? <span style={{ color: "green", fontWeight: "bold" }}>{"생성 완료"}</span> :
																(item.status == "failed") ? <span style={{ color: "red" }}>{"생성 실패"}</span> : <span style={{ color: "red" }}>{"생성 중"}</span>
														}
													</td>
													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateImageUrl(item)}
															alt={item.filename}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(e, item, "input")}
														/>
													</td>
													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateModelUrl(item)}
															alt={`model-${item.filename}`}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(e, item, "model")}
														/>
													</td>

													<td className="table-cell">
														<img
															src={this.generateResultUrl(item)}
															alt={`result-${item.filename}`}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(e, item, "result")}  // Handle Ctrl + Click
														/>
													</td>
													<td className="discard-status" >{
														(item.check_status == "accept") ? <span style={{ color: "green", fontWeight: "bold" }}>{"수락"}</span> : (item.check_status == "reject") ? <span style={{ color: "red" }}>{"제외"}<br></br><Button
															color="info"
															onClick={() => {
																this.getTaskTagList(item.idx);
																this.setState({ isShowRejectReasonOpen: true, currentRejectReason: item.reject_reason });
															}}
														>
															사유보기
														</Button></span> : <span style={{ color: "red", fontWeight: "bold" }}>{"[미선택]"}</span>

													}</td>
													<td className="table-cell">
														<Button
															color="info"
															onClick={() => {
																this.updateCheckStatus(item.idx, "accept");
															}}
														>
															수락
														</Button>
														<br></br>
														{
															(item.check_status != "reject") ? (
																<Button
																	color="danger"
																	onClick={() => {
																		this.updateCheckStatus(item.idx, "reject");
																	}}
																>
																	제외
																</Button>) : ""}
														<br></br>
														<Button
															color="warning"
															onClick={() => {
																this.handleReRun(item);
															}}
														>
															Rerun
														</Button>
													</td>
												</tr>
											))}
										</tbody>
									</table>


								</div>
							</div>
						</Row>
					</Container>
				</div>
			</Fragment >
		);
	}
}

export default CheckPage;
