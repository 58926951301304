import React, { Component } from 'react';

class TaskComponent extends Component {
    handleRemove = () => {
        const { onRemove } = this.props;
        if (onRemove) {
            onRemove();
        }
    };
    generateResultUrl = (item) => {


        return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output&version=${item.version}&width=100&height=100`
    }
    render() {
        const { item, isSelected, isCurrent } = this.props;

        return (

            <div
                style={{

                    width: 100,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: isSelected ? (isCurrent) ? '2px solid #db03fc' : '2px solid #f00' : (isCurrent) ? '2px solid blue' : '2px solid #fff',
                    cursor: 'pointer',
                    margin: '2px',
                    borderRadius: '5px',
                    backgroundColor: '#eee', // Optional for better visibility of question mark
                }}
                onClick={() => { this.props.onClick(item); }}
            >
                {item.status === "succeed" ? (
                    <img
                        src={this.generateResultUrl(item)}
                        alt={item.name || "result"}
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px',
                            objectFit: 'cover',
                        }}
                    />
                ) : (
                    <span style={{
                        fontSize: '2rem',
                        color: '#666',
                        fontWeight: 'bold',
                    }}>?
                    </span>
                )}
            </div>
        );
    }
}


export default TaskComponent;
